import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import sr from '@utils/sr';
import { srConfig } from '@config';
import { Icon } from '@components/icons';
import { usePrefersReducedMotion } from '@hooks';

const StyledProjectsGrid = styled.ul`
  ${({ theme }) => theme.mixins.resetList};

  a {
    position: relative;
    z-index: 1;
  }
`;

const StyledProject = styled.li`
  position: relative;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;

  @media (max-width: 768px) {
    ${({ theme }) => theme.mixins.boxShadow};
  }

  &:not(:last-of-type) {
    margin-bottom: 100px;

    @media (max-width: 768px) {
      margin-bottom: 70px;
    }

    @media (max-width: 480px) {
      margin-bottom: 30px;
    }
  }

  &:nth-of-type(odd) {
    .project-content {
      grid-column: 7 / -1;
      text-align: left;

      @media (max-width: 100px) {
        grid-column: 5 / -1;
      }
      @media (max-width: 768px) {
        grid-column: 1 / -1;
        padding: 40px 40px 30px;
        text-align: left;
      }
      @media (max-width: 480px) {
        padding: 25px 25px 20px;
      }
    }
    .project-tech-list {
      justify-content: flex-end;

      @media (max-width: 768px) {
        justify-content: flex-start;
      }

      li {
        margin: 0 0 5px 20px;

        @media (max-width: 768px) {
          margin: 0 10px 5px 0;
        }
      }
    }
    .project-links {
      justify-content: flex-end;
      margin-left: 0;
      margin-right: -10px;

      @media (max-width: 768px) {
        justify-content: flex-start;
        margin-left: -10px;
        margin-right: 0;
      }
    }
    .project-image {
      grid-column: 1 / 8;

      @media (max-width: 768px) {
        grid-column: 1 / -1;
      }
    }
  }

  .project-content {
    position: relative;
    grid-column: 1 / 7;
    grid-row: 1 / -1;

    @media (max-width: 1080px) {
      grid-column: 1 / 9;
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      grid-column: 1 / -1;
      padding: 40px 40px 30px;
      z-index: 5;
    }

    @media (max-width: 480px) {
      padding: 30px 25px 20px;
    }
  }

  .project-overline {
    margin: 10px 0;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: var(--fz-xs);
    font-weight: 400;
  }

  .project-title {
    color: var(--lightest-slate);
    font-size: clamp(24px, 5vw, 28px);

    @media (min-width: 768px) {
      margin: 0 0 20px;
    }

    @media (max-width: 768px) {
      color: var(--white);

      a {
        position: static;

        &:before {
          content: '';
          display: block;
          position: absolute;
          z-index: 0;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
      }
    }
  }

  .project-description {
    ${({ theme }) => theme.mixins.boxShadow};
    position: relative;
    z-index: 2;
    padding: 25px;
    border-radius: var(--border-radius);
    background-color: var(--light-navy);
    color: var(--light-slate);
    font-size: var(--fz-lg);

    @media (max-width: 768px) {
      padding: 20px 0;
      background-color: transparent;
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }
    }

    a {
      ${({ theme }) => theme.mixins.inlineLink};
    }

    strong {
      color: var(--white);
      font-weight: normal;
    }
  }

  .project-tech-list {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
    margin: 25px 0 10px;
    padding: 0;
    list-style: none;

    li {
      margin: 0 20px 5px 0;
      color: var(--light-slate);
      font-family: var(--font-mono);
      font-size: var(--fz-xs);
      white-space: nowrap;
    }

    @media (max-width: 768px) {
      margin: 10px 0;

      li {
        margin: 0 10px 5px 0;
        color: var(--lightest-slate);
      }
    }
  }

  .project-links {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 10px;
    margin-left: -10px;
    color: var(--lightest-slate);

    a {
      ${({ theme }) => theme.mixins.flexCenter};
      padding: 10px;

      &.external {
        svg {
          width: 22px;
          height: 22px;
          margin-top: -4px;
        }
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .cta {
      ${({ theme }) => theme.mixins.smallButton};
      margin: 10px;
    }
  }

  .project-image {
    ${({ theme }) => theme.mixins.boxShadow};
    grid-column: 6 / -1;
    grid-row: 1 / -1;
    position: relative;
    z-index: 1;

    @media (max-width: 768px) {
      grid-column: 1 / -1;
      height: 100%;
      opacity: 0.25;
    }

    a {
      width: 100%;
      height: 100%;
      background-color: var(--green);
      border-radius: var(--border-radius);
      vertical-align: middle;

      &:hover,
      &:focus {
        background: transparent;
        outline: 0;

        &:before,
        .img {
          background: transparent;
          filter: none;
        }
      }

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
        transition: var(--transition);
        background-color: var(--navy);
        mix-blend-mode: screen;
      }
    }

    .img {
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1) brightness(90%);

      @media (max-width: 768px) {
        object-fit: cover;
        width: auto;
        height: 100%;
        filter: grayscale(100%) contrast(1) brightness(50%);
      }
    }
  }
`;

const Featured = () => {
  const projects = [
    {
      title: 'Investment & Trading Algorithms',
      description: `
        <ul>
          <li>Developed <strong>automated trading algorithms</strong> for long-term capital accumulation and short-term trading strategies.</li>
          <li>Integrated real-time and historical market data from <strong>Binance, IG, and Interactive Brokers APIs</strong>.</li>
          <li>Implemented a <strong>martingale-based strategy</strong>, achieving a <strong>23% ROI in 4 months on €100,000</strong> capital.</li>
          <li>Conducted rigorous <strong>backtesting, parameter tuning, and risk management enhancements</strong> to improve strategy resilience.</li>
          <li>Developed a <strong>custom market scanner</strong> to identify top-performing cryptocurrencies and execute trades dynamically.</li>
        </ul>
      `,
      tech: ['Python', 'Machine Learning', 'Financial APIs', 'Algorithmic Trading'],
      github: 'https://github.com/your-username/trading-algorithms',
      external: 'https://your-website.com/project/trading-algorithms',
    },
    {
      title: 'Full-Stack Website Development',
      description: `
        <ul>
          <li>Built and deployed my <strong>personal website</strong>, managing frontend, backend, and <strong>full-stack infrastructure</strong>.</li>
          <li>Handled <strong>server deployment, proxy configuration, SSL security, and backend optimizations</strong>.</li>
          <li>Designed a <strong>responsive user interface</strong> using React.js and Tailwind CSS.</li>
          <li>Implemented <strong>SEO optimization</strong> and analytics tracking for performance insights.</li>
        </ul>
      `,
      tech: ['React.js', 'Node.js', 'Nginx', 'Google Cloud', 'Docker'],
      github: 'https://github.com/your-username/website',
      external: 'https://your-website.com',
    },
    {
      title: 'AI-Enhanced Automation',
      description: `
        <ul>
          <li>Developed <strong>AI-powered automation tools</strong> integrated with WhatsApp, enabling <strong>ChatGPT conversations, AI-generated images, document interaction, and automated research reports</strong>.</li>
          <li>Designed a backend service to <strong>streamline AI-powered responses and enhance user experience</strong>.</li>
          <li>Integrated <strong>OpenAI API</strong> for natural language processing and intelligent automation.</li>
        </ul>
      `,
      tech: ['React Native', 'Node.js', 'OpenAI API', 'WhatsApp Automation'],
      github: 'https://github.com/your-username/ai-automation',
      external: 'https://your-website.com/project/ai-automation',
    },
    {
      title: 'Blockchain & Smart Contracts',
      description: `
        <ul>
          <li>Developed a <strong>decentralized application (dApp)</strong> and smart contract using <strong>Solidity</strong> on the Ethereum <strong>Sepolia testnet</strong>.</li>
          <li>Implemented an <strong>ERC-20 token</strong>, managing transactions, minting, and transfers securely.</li>
          <li>Built a <strong>custom blockchain explorer interface</strong> to track token activity and smart contract interactions.</li>
          <li>Integrated <strong>Web3.js</strong> to interact with Ethereum blockchain and execute smart contract functions.</li>
        </ul>
      `,
      tech: ['Solidity', 'Ethereum', 'Web3.js', 'Smart Contracts'],
      github: 'https://github.com/your-username/blockchain-project',
      external: 'https://your-website.com/project/blockchain',
    },
    {
      title: 'Enterprise Automation & Management Software',
      description: `
        <ul>
          <li>Developed <strong>enterprise-grade management software</strong> for invoicing, reimbursements, inventory, HR, and access control.</li>
          <li>Designed and implemented a <strong>smart automation hub</strong> for home and industrial device management.</li>
          <li>Built a <strong>real-time monitoring dashboard</strong> with centralized control over automation programs and users.</li>
          <li>Integrated APIs, local and remote databases, and <strong>secure authentication layers (OTP, role-based access control)</strong>.</li>
        </ul>
      `,
      tech: ['React.js', 'Node.js', 'Feathers.js', 'SQL', 'MongoDB'],
      github: 'https://github.com/your-username/enterprise-software',
      external: 'https://your-website.com/project/enterprise-software',
    },
    {
      title: 'AI-Powered Surveillance & Detection',
      description: `
        <ul>
          <li>Engineered an <strong>AI-driven person and object detection system</strong> for security applications.</li>
          <li>Achieved <strong>95% detection accuracy</strong>, even with obscured faces, ensuring <strong>GDPR compliance</strong>.</li>
          <li>Developed a <strong>real-time surveillance system</strong> integrating <strong>computer vision and edge computing</strong> for fast processing.</li>
          <li>Optimized for <strong>low-latency execution on external video surveillance cameras and embedded systems</strong>.</li>
        </ul>
      `,
      tech: ['Python', 'OpenCV', 'TensorFlow', 'Computer Vision'],
      github: 'https://github.com/your-username/ai-surveillance',
      external: 'https://your-website.com/project/ai-surveillance',
    },
  ];  
  

  const revealTitle = useRef(null);
  const revealProjects = useRef([]);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealTitle.current, srConfig());
    revealProjects.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);

  return (
    <section id="projects">
      <h2 className="numbered-heading" ref={revealTitle}>
        Some Things I’ve Built
      </h2>

      <StyledProjectsGrid>
        {projects &&
          projects.map((project, i) => (
            <StyledProject key={i} ref={el => (revealProjects.current[i] = el)}>
              <div className="project-content">
                <div>
                  <p className="project-overline">Featured Project</p>
                  <h3 className="project-title">
                    <a>{project.title}</a>
                  </h3>
                  <div
                    className="project-description"
                    dangerouslySetInnerHTML={{ __html: project.description }}
                  />
                  {project.tech && (
                    <ul className="project-tech-list">
                      {project.tech.map((tech, i) => (
                        <li key={i}>{tech}</li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <div className="project-image">
                <a>
                  {/* Replace with actual GatsbyImage for each project */}
                  <GatsbyImage image={'./../images/logo.png'} alt={project.title} className="img" />
                </a>
              </div>
            </StyledProject>
          ))}
      </StyledProjectsGrid>
    </section>
  );
};

export default Featured;
