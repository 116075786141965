import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;

const StyledText = styled.div`
  .skills-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 Columns */
    grid-gap: 20px; /* Adjust spacing between columns */
    margin-top: 20px;
    list-style: none;
    padding: 0;

    @media (max-width: 900px) {
      grid-template-columns: repeat(2, 1fr); /* 2 columns on medium screens */
    }

    @media (max-width: 600px) {
      grid-template-columns: repeat(1, 1fr); /* 1 column on small screens */
    }

    .skill-category {
      strong {
        font-size: var(--fz-md);
        color: var(--green);
        display: block;
        margin-bottom: 5px;
      }

      ul {
        list-style: none;
        padding-left: 0;

        li {
          position: relative;
          padding-left: 20px;
          font-family: var(--font-mono);
          font-size: var(--fz-xs);
          margin-bottom: 5px;

          &:before {
            content: '▹';
            position: absolute;
            left: 0;
            color: var(--green);
            font-size: var(--fz-sm);
          }
        }
      }
    }
  }
`;

const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;
      transform: translate(-4px, -4px);

      &:after {
        transform: translate(8px, 8px);
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 14px;
      left: 14px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = [
    {
      category: 'Dev Languages',
      items: ['TypeScript', 'JavaScript', 'Python', 'Java', 'C#', 'Solidity'],
    },
    {
      category: 'Frontend Dev',
      items: ['React.js', 'React Native', 'HTML', 'CSS'],
    },
    {
      category: 'Backend Dev',
      items: ['Node.js', 'Feathers.js', '.NET', 'REST APIs', 'OpenAI API', 'WebSocket'],
    },
    {
      category: 'Databases',
      items: ['SQL', 'MongoDB', 'Redis'],
    },
    {
      category: 'Cloud',
      items: ['Google Cloud', 'AWS', 'Nginx', 'PM2'],
    },
    {
      category: 'DevOps',
      items: ['Docker', 'Git', 'Linux', 'Server Deployment'],
    },
  ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              I'm Giorgio, a software engineer and freelancer with experience in developing innovative solutions across backend, frontend, and server-side architectures.
              My expertise spans API development, web and mobile application design, and server infrastructure management, including SSH server configuration, domain setup, proxy handling, and SSL certificate deployment.
            </p>

            <p>
              Throughout my career, I have delivered software solutions in diverse industries such as security, investment technology, and management systems. My passion lies in leveraging technology to drive efficiency and innovation, with a particular interest in investments, trading, AI, automation, and algorithm development.
            </p>

            <p>Here are a few technologies I’ve been working with recently:</p>
          </div>

          {/* Three-column skills list */}
          <div className="skills-container">
            {skills.map((skillSet, i) => (
              <div key={i} className="skill-category">
                <strong>{skillSet.category}:</strong>
                <ul>
                  {skillSet.items.map((item, j) => (
                    <li key={j}>{item}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/me.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
