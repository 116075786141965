import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { srConfig } from '@config';
import { KEY_CODES } from '@utils';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledJobsSection = styled.section`
  max-width: 1000px;

  .inner {
    display: flex;

    @media (max-width: 600px) {
      display: block;
    }

    @media (min-width: 700px) {
      min-height: 340px;
    }
  }
`;

const StyledTabList = styled.div`
  position: relative;
  z-index: 3;
  width: max-content;
  padding: 0;
  margin: 0;
  list-style: none;

  @media (max-width: 600px) {
    display: flex;
    overflow-x: auto;
    width: calc(100% + 100px);
    padding-left: 50px;
    margin-left: -50px;
    margin-bottom: 30px;
  }
`;

const StyledTabButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--tab-height);
  padding: 0 20px 2px;
  border-left: 2px solid var(--lightest-navy);
  background-color: transparent;
  color: ${({ isActive }) => (isActive ? 'var(--green)' : 'var(--slate)')};
  font-family: var(--font-mono);
  font-size: var(--fz-xs);
  text-align: left;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: var(--light-navy);
  }
`;

const StyledHighlight = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 2px;
  height: var(--tab-height);
  background: var(--green);
`;

const StyledTabPanels = styled.div`
  position: relative;
  width: 100%;
  margin-left: 30px;
`;

const StyledTabPanel = styled.div`
  width: 100%;
  height: auto;
  padding: 10px 5px;

  h3 {
    margin-bottom: 2px;
    font-size: var(--fz-xxl);
    font-weight: 500;
    line-height: 1.3;

    .company {
      color: var(--green);
    }
  }

  .range {
    margin-bottom: 25px;
    color: var(--light-slate);
    font-family: var(--font-mono);
    font-size: var(--fz-xs);
  }
`;

const Jobs = () => {
  const jobs = [
    {
      title: 'Software Developer',
      company: 'Tecnel MC S.à.r.l.',
      range: 'June 2023 - Present',
      description: `
        <ul>
          <h3><strong>INDUSTRY APPLICATIONS</strong></h3>
            <li>Developed a smart automation hub for managing home and industrial devices and created the interface for controlling programs, scenes, access, and users in real-time.</li>
            <li>Designed and developed enterprise-grade management software for invoicing, reimbursements, inventory, HR, and access control.</li>
            <li>Engineered a person and object detection software for security applications.</li>
          </ul>
          <ul>
          <h3><strong>BACKEND DEVELOPMENT</strong></h3>
            <li>Built scalable and reliable backend systems for web and mobile apps.</li>
            <li>Developed security layers: OTP generation, API route protection, and role-based access control.</li>
            <li>Managed database migrations and integrations for product management, invoicing, reimbursements, storage, HR, cart functionality, and access control.</li>
            <li>Designed and integrated RESTful APIs for seamless communication between services.</li>
          </ul>
          <ul>
          <h3><strong>FRONTEND DEVELOPMENT</strong></h3>
            <li>Developed responsive UIs with React.js for real-time monitoring and management.</li>
            <li>Built modular dashboard components, data visualizations, and user management interfaces.</li>
            <li>Integrated frontend with backend APIs, ensuring cross-platform compatibility (web, iOS, Android).</li>
          </ul>
          <ul>
          <h3><strong>FINANCIAL & TRADING ALGORITHMS</strong></h3>
            <li>Developed and deployed an algorithm that achieved a 23% ROI in 4 months on an initial €100,000 investment.</li>
          </ul>
        </ul>
      `
    },
    {
      title: 'Insurance Intern',
      company: 'Aon Plc',
      range: 'April 2018 - September 2018',
      description: `
        <ul>
          <li>Handled 200+ car insurance policies, ensuring compliance and client satisfaction.</li>
          <li>Administered insurance policies through various portals including Vittoria, UCA, and Nobis, utilizing specialized insurance software to streamline operations.</li>
          <li>Built and maintained strong relationships with insurance brokers, intermediaries, and customers, delivering high-quality service and support.</li>
        </ul>
      `
    },
    {
      title: 'Bank Intern',
      company: 'BCC Banca di Credito Cooperativo di Pianfei',
      range: 'September 2017 - March 2018',
      description: `
        <ul>
          <li>Monitored productivity and financial reports of five bank branches.</li>
          <li>Monitored customer transactions and assessed solvency using Gesbank software, identifying potential risk factors and working with senior staff to implement solutions.</li>
          <li>Assisted in audits, ensuring compliance with lending, privacy, and anti-money laundering policies.</li>
        </ul>
      `
    }
  ];

  const [activeTabId, setActiveTabId] = useState(0);

  return (
    <StyledJobsSection id="jobs">
      <h2 className="numbered-heading">Where I’ve Worked</h2>

      <div className="inner">
        <StyledTabList role="tablist" aria-label="Job tabs">
          {jobs.map((job, i) => (
            <StyledTabButton
              key={i}
              isActive={activeTabId === i}
              onClick={() => setActiveTabId(i)}>
              <span>{job.company}</span>
            </StyledTabButton>
          ))}
          <StyledHighlight activeTabId={activeTabId} />
        </StyledTabList>

        <StyledTabPanels>
          {jobs.map((job, i) => (
            <CSSTransition key={i} in={activeTabId === i} timeout={250} classNames="fade">
              <StyledTabPanel
                role="tabpanel"
                aria-labelledby={`tab-${i}`}
                hidden={activeTabId !== i}>
                <h3>
                  <span>{job.title}</span>
                  <span className="company">
                    &nbsp;@&nbsp;
                    {job.company === 'Tecnel MC S.à.r.l.' ? (
                      <span>{job.company}</span> // No hyperlink for Tecnel
                    ) : job.company === 'Aon Plc' ? (
                      <a href="https://www.aon.com/en/" target="_blank" rel="noopener noreferrer">
                        {job.company}
                      </a>
                    ) : job.company === 'BCC Banca di Credito Cooperativo di Pianfei' ? (
                      <a href="https://www.pianfeieroccadebaldi.bcc.it/filiali/cuneo/" target="_blank" rel="noopener noreferrer">
                        {job.company}
                      </a>
                    ) : (
                      <a href="#">{job.company}</a>
                    )}
                  </span>
                </h3>
                <p className="range">{job.range}</p>
                <div dangerouslySetInnerHTML={{ __html: job.description }} />
              </StyledTabPanel>
            </CSSTransition>
          ))}
        </StyledTabPanels>
      </div>
    </StyledJobsSection>
  );
};

export default Jobs;
